import React, { useRef, useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import Logo from "../shared/assets/Cherry Logo New 2024 Web Dark 1.webp";
import {VehicleContext} from '../shared/contexts/VehicleContext'
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",

  },

  menuHodlerOuter: {
    width: "100%",
    background: "white",
       padding:'0px 0px',
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: "0px",
    zIndex: "3",
 
  },
  menu: {
    width: "100%",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "white",

  },
  menuHodler: {
    display: "flex",
    background: "white",
    width:'100%',
    paddingRight: '80px', 
    justifyContent: 'end',
    "@media (max-width: 1000px)": {
      display: "none",
    },
    "@media (max-width: 1770px)": {
      paddingRight: '0px', 
    },
  },
  Link: {
    padding: "19px 13px",
    color: "black",
    fontSize: "14px",
    "@media (max-width: 1770px)": {
      fontSize: "12px",
    },
    "&:hover": {
      color: "#b81b22",
      cursor: "pointer",
    },
  },
  LinkActive: {
    padding: "19px 13px",
    color: "black",
    fontSize: "14px",
    borderBottom:'2px solid #b81b22 ',
    "@media (max-width: 1770px)": {
      fontSize: "12px",
    },
    "&:hover": {
      color: "#b81b22",
      cursor: "pointer",
    },
  },
  LinkM: {
    padding: "19px 15px",
    color: "black",

    "&:hover": {
      cursor: "pointer",
    },
  },
  BurgerHolder: {
    position: "relative",
    display: "none",
    paddingRight: '15px',
    "@media (max-width: 1000px)": {
      display: "block",
      position: "relative",
    },
  },
  Burger: {
    display: "none",
    "@media (max-width: 1000px)": {
      display: "block",
    },
  },

  pattie: {
    minWidth: "20px",
    minHeight: "2px",
    margin: "4px",
    background: "black",
  },
  img: {
    clipPath: 'polygon(0 0, 92% 0, 100% 100%, 0% 100%)',
    width: "50%",
    padding:'11px 0px',
    paddingLeft: '80px', 
    background:'#d7d6d6',
    "@media (max-width: 768px)": {
      width: "60%",
      paddingLeft: '20px', 
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "60%",
    },
  },
  logo:{
    width: "250px", 
    "@media (max-width: 768px)": {
      width: "60%",
      
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      width: "200px",
    },
  },
  dropDown: {
    transform:({showRoomDropDown}) => `translate(0px,${showRoomDropDown}%)`,
    width: "100%",
    right: "0px",
    top: "0px",
    position: `absolute`,
    transition:'0.5s all ease-in',
    background: "white",
    padding: "0px 0px",
  zIndex:2,
  boxShadow: "0 0 5px 0 #d7d6d6 ",
  
  },

  dropDownMobileOutter:{
    transform:({openMenu}) => `translate(${openMenu}%,0%)`,
    background: "#b81b22",
    width: "75%",
    right: "0px",
    top: "0px",
    bottom: '0',
    position: ({openMenu}) => `${openMenu ? "fixed": 'fixed'}`,
    transition:'0.5s all ease-in',
  },
  dropDownMobile: {
    
    width: "98%",
    right: "0px",
    top: "0px",
    bottom: '0',
    position: ({openMenu}) => `${openMenu ? "fixed": 'fixed'}`,
    transition:'0.5s all ease-in',
   
    background:'#b6b6b6',
    padding: "0px 10px",
    zIndex:2
  },
  dropDownInner: {
    margin: '0 auto',
    maxWidth: "1170px",
  display:'flex',
  justifyContent:'space-between',
  },
  dropDownInnerHolder:{
    padding:'20px 10px',
    textAlign:'center',
    maxWidth: 'calc(100% / 3)',
position:'relative',
cursor:'pointer',
"&:hover":{
  '&::before': {
    content: '"."',
    color:'white',
    display: 'block',
    height: 20,
    transform: 'rotate(45deg) translate(50%,0%)',
    width:'20px',
    position:'absolute',
    right: '50%',
    top:'-10px',
    background:"white",
  }
}
  
  },

  dropDownInnerBanner:{
 background:"#c7001f",
 margin: '0 auto',
 maxWidth: "1170px",
 display:'flex'
  },
  
  dropDownInnerOutter:{
    background:"#c7001f",
    margin: '0 auto',
  
     },
  dropDownInnerBannerModel:{
    maxWidth: 'calc(100% / 3)',
    width:"100%",
    textAlign:'center',
    color:'white',
     },
  showRoomDropDownHolder: {
    position: "relative",
  },
  showRoomDropDown: {
    position: "absolute",
    background: "#274b84",
    whiteSpace: "nowrap",
  },
  showRoomDropDownLink:{
    padding:'16px 0px',
    color:"black",
    
  },
  showRoomDropDownLinkModel:{
    padding:'16px 0px',
    color:"white",
    cursor:'pointer',
  },
  Close:{
    fontSize: '20px',
    color: '#b81b22',
    textAlign: 'right',
    padding: '10px',
    paddingTop:'20px',
    paddingBottom:'0px',
    fontWeight:'bold',
    cursor:'pointer',
  }
}));

const TopBar = () => {
  

  const [openMenu, setOpenMenu] = useState(100);
  const [showRoomDropDown, setShowRoomDropDown] = useState(-100);

  const {newVehiclesList} = useContext(VehicleContext)

  let obj = {
    openMenu:openMenu,
    showRoomDropDown:showRoomDropDown,
  }
  const classes = useStyles({...obj});

  const history = useHistory()

  const handleMenu = () => {
    if (openMenu === 100) {
      setOpenMenu(0);
    } else {
      setOpenMenu(100);
    }
  };

  const handleShowRoomMenu = (e,val) => {

    if(e,val){
      e.preventDefault()
    }
    
    if (showRoomDropDown === -100) {
   
  
      setShowRoomDropDown(37);
    } else {
  

      setShowRoomDropDown(-100);
    }
  };

  const checkActive = ()=>{
    setShowRoomDropDown(-100)
   
  }
  const handleRedirect = (data) => {

 handleShowRoomMenu()
 {!data.isComingSoon? history.push(`/show-room/${data?.make}/${data?.ownedModelId}`,data) :
 history.push(`/coming-soon/${data?.make}/${data?.ownedModelId}`,data)}
  };

  return (
    <div className={classes.root}>
   

      <Box className={classes.menuHodlerOuter}>
        <Box className={classes.menu}>
          <div className={classes.img}>
            <Link
              to="/"
             
            >
              <img src={Logo} width="100%" className={classes.logo}></img>
            </Link>
          </div>

          <div className={classes.BurgerHolder}>
            <div
              className={classes.Burger}
              onClick={() => {
                handleMenu();
              }}
            >
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
              <div className={classes.pattie}></div>
            </div>

            <div className={classes.dropDownMobileOutter}>
              <div className={classes.dropDownMobile}>
          
           <div onClick={()=>{handleMenu()}} className={classes.Close}><CloseIcon/></div>
                <Link
                  to="/show-room"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.LinkM}>Showroom</Typography>
                </Link>

                <Link
                  to="/used-vehicles"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.LinkM}>Used Vehicles</Typography>
                </Link>

                <Link
                  to="/special-deals"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.LinkM}>
                    Deals
                  </Typography>
                </Link>

                <Link
                  to="/book-a-service"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.LinkM}>
                   Services
                  </Typography>
                </Link>
                

                <Link
                  to="/apply-for-finance"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.LinkM}>Finance</Typography>
                </Link>

                <Link
                  to="/sell-your-vehicle"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <Typography className={classes.LinkM}>Sell a car</Typography>
                </Link>

               

                <Link
                  to="/parts"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                     <Typography className={classes.LinkM}>Parts</Typography>
                </Link>
                
                <Link
                  to="/contact-us"
                  onClick={() => {
                    handleMenu();
                  }}
                >
                     <Typography className={classes.LinkM}>Contact Us</Typography>
                </Link>
              </div>
              </div>
          </div>

          <Box className={classes.menuHodler}>
         
           
            <NavLink
              to="/show-room"
              activeClassName={classes.LinkActive }
              className={classes.Link }
              onClick={(e)=>{handleShowRoomMenu(e,"s")}}
            >
               <Typography  >Showroom</Typography>
            
            </NavLink>
          
         
            <NavLink
              to="/used-vehicles"
              activeClassName={classes.LinkActive }
              className={classes.Link }
            >
              <Typography   onClick={()=>{checkActive() }}>Used Vehicles</Typography>
            </NavLink>

            
            <NavLink
              to="/special-deals"
              activeClassName={classes.LinkActive }
              className={classes.Link }
            >
              <Typography  onClick={()=>{ checkActive()}}>Deals</Typography>
            </NavLink>

            <NavLink
                  to="/book-a-service"
                  activeClassName={classes.LinkActive }
                  className={classes.Link }
            >
              <Typography  onClick={()=>{ checkActive()}}>Services</Typography>
            </NavLink>
         

            <NavLink
              to="/apply-for-finance"
              activeClassName={classes.LinkActive }
                  className={classes.Link }
            >
              <Typography   onClick={()=>{checkActive()}}>Finance</Typography>
            </NavLink>

            <NavLink
              to="/sell-your-vehicle"
              activeClassName={classes.LinkActive }
              className={classes.Link }
            >
              <Typography   onClick={()=>{ checkActive()}}>Sell a car</Typography>
            </NavLink>

           

        
            
            <NavLink
               to="/parts"
               activeClassName={classes.LinkActive }
               className={classes.Link }
            >
              <Typography   onClick={()=>{ checkActive()}}>Parts</Typography>
                </NavLink>

         
                <NavLink
              to="/contact-us"
              activeClassName={classes.LinkActive }
              className={classes.Link }
            >
              <Typography  onClick={()=>{ checkActive()}}>Contact Us</Typography>
            </NavLink>
            
          </Box>
        
        </Box>
   
      </Box>
  
      <div className={classes.dropDown}>
        <div className={classes.dropDownInnerOutter}>
      <div className={classes.dropDownInnerBanner}>
      {newVehiclesList?.map((v)=>{
               return(
                <div className={classes. dropDownInnerBannerModel} onClick={()=>{handleRedirect(v)}}>
                
                 <Typography className={classes.showRoomDropDownLinkModel} >{v?.title}</Typography>
            
                </div>
               )
             })}
           </div>
           </div>
           <div className={classes.dropDownInner}>
         
     
             {newVehiclesList?.map((v)=>{
               return(
                <div className={classes.dropDownInnerHolder} onClick={()=>{handleRedirect(v)}}>
                
              
                 <img src={v?.image} width="70%"/>
                 {/* <Typography className={classes.showRoomDropDownLink} >From R {v?.price}</Typography> */}
               
                </div>
               )
             })}
         
              </div>
      </div> 
    
    </div>
  );
};

export default TopBar;
