import React, { createContext, useState, useEffect } from 'react';

export const FavouriteContext = createContext();

export const FavouriteProvider = ({ children }) => {

    const [favouriteVal, setFavouriteVal] = useState([]);

    return (
        <FavouriteContext.Provider value={{ favouriteVal, setFavouriteVal }}>
            {children}
        </FavouriteContext.Provider>
    )
};