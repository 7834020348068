import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";

import { VehicleContext } from "../shared/contexts/VehicleContext";
import { UsedVehiclesFilter } from "@red-build/leadcentre";
import { BASE_URI, DEALERID } from "../shared/Constants";

import { useHistory, useParams } from "react-router-dom";
import { padding } from "@mui/system";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "40px 15px",
    display: "flex",
    flexDirection:'column',
    
    "@media (max-width: 768px)": {},
  },
  innerHolderFilter: {
    maxWidth: "1770px",
  width:'100%',
    margin: " 0 auto",
  },
  innerHolder: {
    maxWidth: "1770px",
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    width: "100%",
  },
  card: {
    width: "calc(100% / 4 - 20px )",
    minWidth: "calc(100% / 4 - 20px )",
    cursor:'pointer',
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    margin: "10px 10px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    "@media (max-width: 700px)": {
      width: "100%",
    },
    "@media (min-width: 701px) and (max-width: 999px)": {
      width: "calc(100% / 2 - 20px )",
    },
    "@media (min-width: 1000px) and (max-width: 1500px)": {
      width: "calc(100% / 3 - 20px )",
    },

   
  },
  cardTextHolder: {
    padding: "20px",
    "@media (max-width: 768px)": {},
  },
  cardImgHolder: {
    height:'250px',
    backgroundSize:'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    "@media (max-width: 768px)": {
      height:'250px',
    },
  },
  cardTextHolderGroup:{
    display:'flex',
    justifyContent:'space-between',
    margin:'5px 0px'
  },
  cta: {
  border: "none",
  borderRadius:'1rem',
  padding: "10px 40px",
  background: "#b81b22",
  color: "white",
  cursor: "pointer",
  margin:'0 auto',
  display:'block',

  "&:hover": {
    background: "  #9b161c",
    boxShadow: "0 0 5px 0  #9b161c",
  },
},
  cardTextTitleHolder:{
 
    marginBottom:'10px'
  },
  cardTextTitle:{
    fontSize:'19px',
    fontWeight:'500',

  },

  pagHolder:{
    display:'flex',
    justifyContent:'center'

  },
  
  pagBtn : {
    border: "none",
    borderRadius:'1rem',
    padding: "10px 40px",
    background: "#b81b22",
    color: "white",
    cursor: "pointer",
    margin:'10px',
    padding:'10px 40px',
  
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  pagNumberHolder:{
    textAlign:'right',
    width: '100%',
    padding: '5px 10px',
  }
}));

const UsedVehicles = () => {
  const history = useHistory();

  const classes = useStyles();
  const { vehiclesList, setVehiclesList } = useContext(VehicleContext);

  const [vehicles, setVehicles] = useState(vehiclesList);
  const [vehiclesPag, setVehiclesPag] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(20);
  



  const handleRedirect = (v) => {
    history.push(`/used-vehicles/chery/${v.stockId}`, v);
  };

  const defaultColors = {
    primaryColor: "black",
    secondaryColor: "#b81b22",
    labelColor:'black',
  };

  const multiSelect = true;

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  



  useEffect(() => {

    let startIndex = 0
    let endIndex = 20

    let pag = []

 

  for (let index = 0; index <= vehiclesList?.length / 20; index++) {
    pag?.push(vehiclesList?.slice(startIndex,endIndex))
    
    startIndex = endIndex 
    endIndex = endIndex + 20

  }

  setVehiclesPag(pag)

 
  }, [vehiclesList])
  

 

  const handleNextPage =()=>{
 
 
    if(vehiclesPag[pageIndex].length >= 20 ){
      setCount(count+ 20) 
      setPageIndex(pageIndex + 1)
    }
  }
  const handleBackPage =()=>{
    if(pageIndex > 0){
      setPageIndex(pageIndex - 1 )
    }
    
   
  }
  
  return (
    <>
  
 
    <div className={classes.root}>

      <div className={classes.innerHolderFilter}> 
    <UsedVehiclesFilter
    pageSize={20}
    dealerId={76}
    motorgroupId={7}
    orientation='row'
    updateFilteredVehicles={setVehiclesPag}
    vehicles={vehiclesList}
    base_uri={BASE_URI}
    websiteColors={defaultColors}
    multiSelect={multiSelect}
  />
  </div>

      <div className={classes.innerHolder}>
        {vehiclesPag[pageIndex]?.map((v, index) => {
          return (
            <div
              className={classes.card}
              onClick={() => {
                handleRedirect(v);
              }}
            >
              <div className={classes.cardImgHolder} style={{backgroundImage:`url(${v?.image})`}}>
             
              </div>
     

              <div className={classes.cardTextHolder}>
              
              <div className={classes.cardTextTitleHolder}>
           
                <div className={classes.cardTextTitle}>{v?.model}</div>
                </div>

                <div  className={classes.cardTextHolderGroup}>
                <div>Mileage</div>
                <div>{v?.mileage} km</div>
                </div>

                <div  className={classes.cardTextHolderGroup}>
                <div>Price</div>
                <div>   {formatter?.format(v?.price)}</div>
                </div>
             
                <div  className={classes.cardTextHolderGroup}>
                <div>Dealership</div>
                <div>{v?.dealershipName}</div>
                </div>

                <div  className={classes.cardTextHolderGroup}>
                <div>Colour</div>
                <div>{v?.colour}</div>
                </div>
            
                <div  className={classes.cardTextHolderGroup}>
                <div>Year</div>
                <div>{v?.year}</div>
                </div>

                <div  className={classes.cardTextHolderGroup}>
                <div>Fuel Type</div>
                <div>{v?.fuelType == "Petrol" || v?.fuelType == "P" ? "Petrol" : "Diesel"}</div>
                </div>

         
                <button className={classes.cta}>Enquire</button>
              </div>
            
            </div>
          );
        })}

        <div className={classes.pagNumberHolder}>{pageIndex} / {(vehiclesList?.length / 20).toFixed(0)}</div>
      </div>
      <div className={classes.pagHolder}>

    
      <button className={classes.pagBtn} onClick={()=>{handleBackPage()}}>Back </button>
      <button className={classes.pagBtn} onClick={()=>{handleNextPage()}}>Next</button>
      </div>
    </div>
    </>
  );
};

export default UsedVehicles;
