import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, MASTER_DEALERID, PAGE_SIZE } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext';

export default function DealsFilter(pageNumber, sortDirection) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [deals, setDeals] = useState([])
    const [hasMore, setHasMore] = useState(false)
    let { globalDealer } = useContext(DealerContext);
    let currentDate = new Date()

    useEffect(() => {
        setLoading(true);
        setError(false);

        const params = {
            pageNumber,
            pageSize: PAGE_SIZE,
            status: 'active',
            dealerId:  globalDealer?.id || MASTER_DEALERID
        }
        // console.log('loading offers for ', ...globalDealer);
        axios({
            method: 'GET',
            url: `${BASE_URI}/Offers`,
            params,
        }).then((response) => {
            setHasMore(response.data?.list.length > 0)
            setLoading(false)
            let filterd = response.data?.list.filter(d => d.dateTo >= currentDate.toISOString() && d.dateFrom <= currentDate.toISOString())
            setDeals(response.data?.list)

        }).catch(error => {
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, sortDirection, globalDealer])

    return { loading, error, deals, hasMore }
}