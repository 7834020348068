import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Banner from "../shared/assets/appbanner.jpg";
import PlayStore from "../shared/assets/Group-8.svg";
import AppleStore from "../shared/assets/Group-7.svg";

const useStyles = makeStyles(() => ({
  root: {},
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px",
    margin: "0 auto",
    "@media (max-width: 959px)": {
      flexWrap: "wrap",
      padding: "0px 0px",
    },
  },
  innerSectionFlex: {
    display: "flex",
    margin: "30px 0px",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 959px)": {
      flexDirection:'column',
      
    },
  },
  titleTop: {
    fontSize: "40px",
    color: "#b81b22",
    paddingBottom: "30px",
  },
  btnHolder: {
    display: "flex",
    width: "50%",
    "@media (max-width: 959px)": {
      width: "unset",
    },
  },
  btn: {
  
    margin: "0px 15px",
    width: "80%",
 
    "@media (max-width: 959px)": {
      width: "80%",
      margin:'0 auto',
      display:'block',
      marginTop: "40px",
    },
  },
  divider: {
    background: "#b81b22",
    width: "60px",
    height: "5px",
    margin: "10px 0px",
  },
  textHolder: {
    width: "48%",
    padding: "30px",
    background:
      "linear-gradient(90deg, rgba(227,227,227,1) 20%, rgba(199,198,198,1) 100%)",
      "@media (max-width: 959px)": {
        width: "unset",
        margin: "0px 15px",

      },
  },
  text: {
    display: "block",
    paddingTop: "30px",
    fontSize: "16px",
  },
}));

const CheryApp = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <img src={Banner} width="100%" />
        <div className={classes.innerSectionFlex}>
          <div className={classes.textHolder}>
            <Typography variant="h1" className={classes.titleTop}>
              My Chery App is here!
            </Typography>
            <div className={classes.divider}> </div>
            <Typography variant="body" className={classes.text}>
              To make your experience faster, to stay informed and connected,
              offering you different features to ensure you have the best
              experience.
            </Typography>
          </div>

          <div className={classes.btnHolder}>
            <a
              href="https://apps.apple.com/za/app/my-chery/id1596279425"
              target="_blank"
            >
              <img className={classes.btn} src={AppleStore} width="100%" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=solutions.dawpro.mychery"
              target="_blank"
            >
              <img className={classes.btn} src={PlayStore} width="100%" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheryApp;
