import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography, TextField, Grid, MenuItem } from "@material-ui/core";
import { ContactUs } from "@red-build/leadcentre";
import { DEALERID, BASE_URI, MASTER_DEALER_NAME } from "../shared/Constants";
import { Link } from "react-router-dom";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import axios from "axios";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 15px",
  },
  innerHolder: {
    maxWidth: "1170px",
    width: "100%",
  },
  CardHolder: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "20px",
  },
  CardHolder50: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "0px 10px",
    marginTop: "20px",
    width: "calc(100%/2 - 10px)",

    "@media (max-width: 959px)": {
      width: "calc(100%)",
    },
  },

  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 30px",
    flexDirection: "column",
  },
  cardContent50: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 0px",
    flexDirection: "column",
  },
  title: {
    borderLeft: "3px #b81b22 solid",
    padding: "5px 30px",
    alignItems: "center",
    display: "flex",

    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  cardContentItem: {
    display: "flex",
    padding: "10px 0px",
    borderBottom: "2px #8080802e solid",
    justifyContent: "space-between",
    alignItems: "center",
  },
  CardHolderWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  accent: {
    color: "#b81b22 ",
  },
  tc: {
    padding: "10px",
    paddingTop: "20px",
  },
  titleTop: {
    fontSize: "20px",
    fontWeight: "unset",
  },
  titleTopAccent: {
    fontSize: "20px",
    color: "#c7001f",
    fontWeight: "bold",
  },
  cardContentFeature: {
    boxShadow: "2px 0px 15px -2px #adadad",
    borderRadius: "2px",
    margin: "0px 10px",
    marginBottom: "10px",
    padding: "10px",
    width: "calc(100% / 2 - 20px )",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  ImgHolder: {
    display: "flex",
    marginTop: "20px",
    "@media (max-width: 760px)": {
      flexDirection: "column",
    },
  },
  ImgGalleryHolder: {
position:"relative",

  },

  ImgGalleryHolderInner: {
    display: "flex",
    transform: ({ galleryPos }) => `translate(${galleryPos}%,0%)`,
  },
  GalleryImg: {
    minWidth: "calc(100% / 6 - 10px)",
    margin: "5px",
    width: "100%",
    height:'90px'
  },

  featureHolder: {
    display: "flex",
    flexWrap: "wrap",
    width: "30%",
    "@media (max-width: 760px)": {
      marginTop: "20px",
      width: "unset",
    },
  },
  img: {
    width: "70%",
    overflow: "hidden",
    "@media (max-width: 760px)": {
      width: "unset",
    },
  },
  imgWide: {
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 760px)": {
      width: "unset",
    },
  },
  Link: {
    border: "none",
    borderRadius:'1rem',
    padding: "10px 40px",
    background: "#b81b22",
    color: "white",
    cursor: "pointer",
  
  
    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  titleFlex: {
    display: "flex",
    alignItems: "center",
    flexWrap:'wrap'
  },
  divider: {
    margin: "0px 10px",
    background: "#b81b22",
    minWidth: "3px",
    minHeight: "35px",
    "@media (max-width: 760px)": {
      display:'none'
    },
  },
  nextbtn: {
    background: '#d7d6d6',
    border: 'none',
    right: '0',
    padding: '10px',
    color:'black',
    opacity: '0.5',
    position:"absolute",
    top: '50%',
    transform: 'translate(0px, -50%)',
    '&:hover': {
      background: '#b81b22',
  },
  },
  backbtn: {
    position:"absolute",
    top: '50%',
    transform: 'translate(0px, -50%)',
    background: '#d7d6d6',
    border: 'none',
    left: '0',
    padding: '10px',
    color:'black',
    opacity: '0.5',
    '&:hover': {
      background: '#b81b22',
  },
},
priceHolder:{
  margin:"20px 0px",
  display: "flex",
  alignItems: "center",
  flexWrap:'wrap'
},
}));

const DetailedUsedVehicle = () => {
  const [activeVehicle, setActiveVehicle] = useState();
  const history = useHistory();
  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
  const [galleryPos, setGalleryPos] = useState(0);

  const params = useParams();
  const classes = useStyles({ galleryPos });
  const { vehiclesList } = useContext(VehicleContext);

  useEffect(() => {
    setActiveVehicle(
      vehiclesList.filter((v) => v.stockId == params?.stockId)[0]
    );
  }, [vehiclesList]);

  useEffect(() => {
    axios({
      method: "GET",
      url: `${BASE_URI}/stock/${params?.stockId}`,
    })
      .then((response) => {
        setActiveGallery(response.data.mainUrls);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  }, [activeVehicle]);

  var formatter = new Intl.NumberFormat("af", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 2,
  });

  const handleBack = () => {
    history.push("/used-vehicles");
  };

  const handleGalleryNext = () => {
    if (
      Math.abs(galleryPos) < Math.abs(((activeGallery.length - 1) / 2) * 16.66)
    ) {
      setGalleryPos(galleryPos - 16);
    }
  };

  useEffect(() => {
    console.log("activeGalleryIndex", activeGalleryIndex);
  }, [activeGalleryIndex]);

  const handleGalleryBack = () => {
    if (galleryPos < 0) {
      setTimeout(() => {
        setGalleryPos(galleryPos + 16);
      }, 100);
    }
  };

  const handleGalleryIndex = (index) => {
    console.log("index", index);
    setActiveGalleryIndex(index);
  };
  

  
  function calculateMonthly(data) {
    console.log(data)

    let deposit = data?.price * (10 / 100);
    let totalPrice = data?.price;
    let initFee = 0;
    let principal = totalPrice - deposit + initFee;

    let balloonPerc = 10;

    let balloonAmt = (totalPrice * balloonPerc) / 100;

    let interestRate = 9
    let interestPM = interestRate / 100 / 12;

    let repaymentPeriod = 72;
    let days = 1;

    let v = 1 / (1 + interestPM);
    let d = 1 - v;
    let y = Math.pow(v, repaymentPeriod - 1);
    let comp = (1 - y) / d;
    let fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
        let comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
        monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
        let comp = (1 - Math.pow(v, repaymentPeriod)) / d;
        monthly = fp / comp;
    }

    return round(monthly) + initFee;

}


function round(x) {

  return Math.round(x * 100) / 100;

}


  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <button
          className={classes.Link}
          onClick={() => {
            handleBack();
          }}
        >
          Back
        </button>
        <div className={classes.CardHolder}>
          <div className={classes.title}>
            <div className={classes.titleFlex}>
              <Typography variant="h4" className={classes.titleTop}>
                {activeVehicle?.model}
              </Typography>
              <div className={classes.divider}></div>
             <div className={classes.priceHolder}>
               
             <Typography variant="h4" className={classes.titleTop}>
                {formatter.format(activeVehicle?.price)}
              </Typography>
              <Typography variant="h4" className={classes.titleTop}>
              &nbsp;or {formatter.format(calculateMonthly(activeVehicle))} pm
              </Typography>
             </div>
        
            </div>
            <Link to="/apply-for-finance">
              <button className={classes.Link}>Pre-Qualify</button>
            </Link>
          </div>
        </div>

        <div className={classes.ImgHolder}>
          <div
            className={
              activeVehicle?.features !== "" ? classes.img : classes.imgWide
            }
          >
            <img src={activeGallery[activeGalleryIndex]} width="100%" />
            <div className={classes.ImgGalleryHolder}>

            <div className={classes.ImgGalleryHolderInner}>
              {activeGallery.map((i, index) => {
                return (
                  <div className={classes.GalleryImg}    onClick={() => {
                    handleGalleryIndex(index)
             
                  }}>
                    <img
                      src={i}
                      width="100%"
                   
                    />
                  </div>
                );
              })}
            </div>

            
            
            <button
              onClick={() => {
                handleGalleryBack();
              }}
              className={classes.backbtn}
            >
              <ArrowBackIosNewIcon/>
            </button>
            <button
              onClick={() => {
                handleGalleryNext();
              }}
              className={classes.nextbtn}
            >
           <ArrowForwardIosIcon/>
            </button>
         
            </div>
          </div>

          {activeVehicle?.features !== "" && (
            <div className={classes.featureHolder}>
              {activeVehicle?.features.split(",").map((f) => {
                if (f !== " ") {
                  return <div className={classes.cardContentFeature}>{f}</div>;
                }
              })}
            </div>
          )}
        </div>

        <div className={classes.CardHolderWrap}>
          <div className={classes.CardHolder50}>
            {/* <div className={classes.title}>
            <Typography variant="h4" className={classes.titleTop}>
              Summary
            </Typography>
          </div> */}

            <div className={classes.cardContent}>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Purchase Price
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {formatter.format(activeVehicle?.price)}
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Year
                </Typography>
                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.year}
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Mileage
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.mileage} km
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Fuel type
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.fuelType == "P" ? "Petrol" : "Diesel"}
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Transmission
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.transmission == "M" ? "Manual" : "Automatic"}
                </Typography>
              </div>

              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Colour
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.colour}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Stock No #
                </Typography>

                <Typography variant="body" className={classes.accent}>
                  {activeVehicle?.stockNo}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.CardHolder50}>
            <div className={classes.title}>
              <Typography variant="h4" className={classes.titleTop}>
                Contact Us
              </Typography>
            </div>

            <div className={classes.cardContent50}>
              <ContactUs
                dealerId={activeVehicle?.dealerId}
                base_uri={BASE_URI}
                leadTypeId={5}
                dealerName={activeVehicle?.dealershipName}
              />
            </div>
          </div>
        </div>
      </div>
      <Typography variant="body" className={classes.tc}>
        {activeVehicle?.termsAndConditions}
      </Typography>
    </div>
  );
};

export default DetailedUsedVehicle;
