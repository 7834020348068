import React, { useContext } from "react";
import { DealerContext } from "../shared/contexts/DealerContext";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: "60px",
    marginBottom: "60px",
  },
  innerSection: {
    width: "100%",
    maxWidth: "1170px",
    display: "flex",
    justifyContent: "space-between",
    padding: "6px 15px",

    "@media (max-width: 959px)": {
      flexWrap: "wrap",
      padding: "0px 0px",
    },
  },
  section: {
    width: "60%",

    "&:nth-child(1)": {
      marginRight: "40px",
      width: "40%",
    },
    "@media (max-width: 959px)": {
      width: "100%",
      margin: "15px",

      "&:nth-child(1)": {
        margin: "0px",
        padding: "0px 20px",
        width: "100%",
      },
    },
  },
  sectionRight: {
    width: "60%",
    display: "flex",

    "&:nth-child(1)": {
      marginRight: "40px",
      width: "40%",
    },
    "@media (max-width: 959px)": {
      width: "100%",
      margin: "15px",
      flexWrap: "wrap",
      "&:nth-child(1)": {
        margin: "0px",
      },
    },
  },
  titleTop: {
    fontSize: "44px",
    fontWeight: "bold",
  },
  titleTopAccent: {
    fontSize: "52px",
    color: "#c7001f",
    fontWeight: "bold",
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
    overflow: "hidden",
  },
  black: {
    backgroundColor: "#d6ebf9",
    padding: theme.spacing(0.5, 0, 0.5),
    fontWeight: "bold",
    color: "white!important",
    cursor: "pointer",
    padding: "10px 10px",
  },
  text: {
    color: "black",
  },
  blacktext: {
    color: "white",
  },
  yellow: {
    backgroundColor: ({ primaryColor }) => `${primaryColor}`,
    padding: theme.spacing(0.5, 0, 0.5),
    color: "white!important",
    cursor: "pointer",
    padding: "10px 10px",
  },
  dividerTitle: {
    backgroundColor: ({ primaryColor }) => `${primaryColor}`,
    width: "33%",
    marginBottom: "30px",
    height: "3px",
    margin: "20px auto",
  },
  dividerDealer: {
    backgroundColor: ({ primaryColor }) => `${primaryColor}`,
    width: "33%",
    marginBottom: "15px",
    marginTop: "15px",
    height: "2px",
  },
  fontSmall: {
    fontSize: "0.7rem",
  },
  sideBorder: {
    maxWidth: "calc(100% / 2)",
    padding: "15px",
    margin: "10px",
    width: "100%",
    boxShadow: "2px 0px 15px -2px #adadad",
    "@media(min-width: 0px) and (max-width: 425px)": {
      maxWidth: "100%",
    },
    "@media(min-width: 426px) and (max-width: 900px)": {
      maxWidth: "100%",
    },
    "@media(min-width: 900px) and (max-width: 1023px)": {
      maxWidth: "47%",
    },
  },
  fontSmall: {
    display: "flex",
  },

  fontSmallHeading: {
    display: "flex",
    fontWeight: "bold",
  },

  textHolder: { 
   paddingTop:"20px",
//    paddingBottom:'20px'
  },
  numberHolder: {
    display: "flex",
    "@media (max-width: 425px)": {
      flexDirection: "column",
    },
  },
  mapholder: {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 425px)": {
      flexDirection: "column",
    },
  },
  mapBannerHolder: {
    width: "100%",
  },
  mapIframeHolder: {
    width: "100%",
  },
  brandHolder: {
    margin: "10px 0px",
  },
}));

const cleanName = (dealerName) => {
  return dealerName
    .replace(/ /g, "-")
    .replace(/LSG-Group---/g, "")
    .toLowerCase();
};

const Dealers = () => {
  const history = useHistory();

  const { dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ ...websiteColors });

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.titleTop}>
            BRANCH
          </Typography>
          <Typography variant="h1" className={classes.titleTopAccent}>
            LOCATOR
          </Typography>

          <Typography variant="body2" className={classes.text}>
            Locate a branch near you
          </Typography>
        </div>
        <div className={classes.sectionRight}>
          {dealerList?.map((dealer, index) => {
            return (
              <div key={index} className={classes.sideBorder}>
                <div>
                  <Typography variant="h6">{dealer.name}</Typography>

                  <div  className={classes.textHolder}>
                    <div
                      fontWeight="fontWeightBold"
                      className={classes.fontSmallHeading}
                    >
                      Address
                    </div>
                    <div className={classes.fontSmall}>
                      {dealer?.address?.streetAddress} ,{" "}
                      {dealer?.address?.streetAddressPostal}
                    </div>

                    <div
                      fontWeight="fontWeightBold"
                      className={classes.fontSmallHeading}
                    >
                      Business Hours
                    </div>
                    <div className={classes.fontSmall}>
                      Monday - Friday: 8:00 - 17:00
                    </div>
                    <div className={classes.fontSmall}>
                      Saturday: 8:00 - 13:00
                    </div>
                    <div
                      fontWeight="fontWeightBold"
                      className={classes.fontSmallHeading}
                    >
                      Contact No
                    </div>
                    <div className={classes.fontSmall}>
                      {dealer?.newCarsPhoneNumber}
                    </div>

                    <div key={index} className={classes.brandHolder}>
                      <img src={dealer.relatedBrands[0].logo} width="100px" />
                    </div>
                  </div>

                  <div className={classes.black}>
                    <div
                      className={classes.text}
                      onClick={() =>
                        (window.location.href = `/dealer/${cleanName(
                          dealer.name
                        )}/used-vehicles`)
                      }
                    >
                      View Used Vehicles From {dealer.name}
                    </div>
                  </div>
                  <div className={classes.yellow}>
                    <div
                      className={classes.blacktext}
                      onClick={() =>
                        (window.location.href = `/dealer/${cleanName(
                          dealer.name
                        )}`)
                      }
                    >
                      Go To The {dealer.name} Website
                    </div>
                  </div>
                  <div className={classes.mapholder}>
                    <div className={classes.mapIframeHolder}>
                      <iframe
                        src={`https://maps.google.com/maps?q=${dealer.addressLongitude},${dealer.addressLatitude}&via=0&z=16&output=embed`}
                        height="100%"
                        width="100%"
                        frameBorder="none"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dealers;
