import React,{ useState } from 'react'
import DealsFilter from './DealsFilter';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import Slide1 from "../shared/assets/Slide1.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding:'0px 0px'

    },
    background: {
    padding:'40px 15px'

    },


    title: {
      fontSize: '2rem',
      color: 'white',
      textTransform: 'uppercase',
    },

    cardHolder:{
      maxWidth: '1170px',
      display:'flex',
      justifyContent:'center',
      flexWrap:'wrap',
      margin: '0 auto',

    },
    card:{
      width: '100%',
      margin: '10px',
      maxWidth: 'calc(100% / 2 - 20px)',
      cursor:'pointer',
      "@media (max-width: 425px)": {
        maxWidth: 'calc(100% / 1 - 20px)',

      },
      "@media (min-width: 769px) and (max-width: 1200px)": {
        maxWidth: 'calc(100% / 2 - 20px)',
      },
    },

  }));

  const useGridStyles = makeStyles(({ breakpoints }) => ({
    root: {
      [breakpoints.up('md')]: {
        // justifyContent: 'center',
      },
    },
  }));


  export default function SpecialDeals() {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const gridStyles = useGridStyles({ color: '#ff9900' });
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [sortDirection, setSortDirection] = useState('Desc')
    // eslint-disable-next-line no-unused-vars
    const [pageNumber, setPageNumber] = useState(1)

    const{
      // loading,
      deals,
      // hasMore
    }= DealsFilter(pageNumber, sortDirection)



  const handlePageChange = (deal)=>{

      history.push(`/special-deals/${deal?.make}/${deal?.offerId}`,deal)
  }

    return (

      <div className={classes.root}>
      <img src={Slide1} width="100%"/>
            <div className={classes.background}>

                <div  className={classes.cardHolder} >

                      {
                  (deals.length > 0) &&
                    deals.map((deal,index) =>
                      <div  key={index}  className={classes.card} >
                            <img onClick={()=>{handlePageChange(deal)}} src={deal?.imageUrl} width="100%"/>
                      </div>
                    )
                }
                 </div>

            </div>

            </div>

    );
  }

