import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core'
import Drive from '../shared/assets/steering-wheel.svg'
import Phone from '../shared/assets/phone-call.svg'
import Placeholder from '../shared/assets/placeholder.svg'
import Mobile from '../shared/assets/noun_Mobile_2969983.svg'
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
    
        transform:(hidden)=>`translate(${hidden}px, -50% )`,
        position: 'fixed',
        top: '50%',
        zIndex: '90',
        background: '#cccccc',
        right: '0',
        transition:'0.2s all ease-in',
        borderRadius: '15px 0px 0px 15px',
        boxShadow: 'rgb(0 0 0 / 38%) 0px 0px 15px',
        '@media (min-width:0px) and (max-width:768px)':{
        }
    },
    section:{
        padding:'8px',
        display:'flex',
        borderRadius: '15px 0px 0px 15px',

        alignItems: 'center',
       
        '& a':{
            display: 'flex',
            alignItems: 'center',
            color:'black'
           },
        '&:nth-child(1)':{
            '&:hover':{
                background: 'red',
                borderRadius: '15px 0px 0px 0px',
               '& img':{
                filter: 'saturate(0) brightness(5)'
               },
               '& div':{
                color: 'white'
               }
             }
        },
        '&:nth-child(2)':{
            '&:hover':{
                background: 'red',
                borderRadius: '0px 0px 0px 0px',
                '& img':{
                    filter: 'saturate(0) brightness(5)'
                   },
                   '& div':{
                    color: 'white'
                   }
             }
        },
        '&:nth-child(3)':{
            '&:hover':{
                background: 'red',
                borderRadius: '0px 0px 0px 0px',
                '& img':{
                    filter: 'saturate(0) brightness(5)'
                   },
                   '& div':{
                    color: 'white'
                   }
             }
        },
        '&:nth-child(4)':{
            '&:hover':{
                background: 'red',
                borderRadius: '0px 0px 0px 15px',
                '& img':{
                    filter: 'saturate(0) brightness(5)'
                   },
                   '& div':{
                    color: 'white'
                   },
                  
             }
        }
    },
    sectionIcon:{
        width: "40px",
    },
    Icon:{
        
    },
    sectionText:{
        padding:'0px 10px',
        textTransform:'uppercase'
    }
}))

const SidePopUp = () => {
    const [hidden, setHidden] = useState(165);
    const classes = useStyles(hidden);
    
  return (
    <div className={classes.root} onMouseEnter={()=>{setHidden(0)}} onMouseLeave={()=>{setHidden(165)}}>

        <div className={classes.section}>
        <Link to="/book-a-testdrive">
        <div className={classes.sectionIcon} >
        <img src={Drive} alt="Drive" width="100%" className={hidden?  " ": classes.Icon} />
        </div>
        <div className={classes.sectionText} >
        Book A Test Drive
        </div>
        </Link>
        </div>

        <div className={classes.section}>
        <Link to="/contact-us">
        <div className={classes.sectionIcon} >
        <img src={Phone} alt="Drive" width="100%" className={hidden?  " ": classes.Icon}/>
        </div>
        <div className={classes.sectionText} >
        Contact Us
        </div>
        </Link>
        </div>

        <div className={classes.section}>
        <Link to="/branch-locator">
        <div className={classes.sectionIcon} >
        <img src={Placeholder} alt="Drive" width="100%"/>
        </div>
        <div className={classes.sectionText} >
        Branch Locator
        </div>
        </Link>
        </div>
       
        <div className={classes.section}>
        <Link to="/chery-app">
        <div className={classes.sectionIcon} >
        <img src={Mobile} alt="Drive" width="100%"/>
        </div>
        <div className={classes.sectionText} >
        Chery App
        </div>
        </Link>
        </div>
        
    </div>
  )
}

export default SidePopUp