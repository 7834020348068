import React, { useContext, useState, useEffect, useRef } from "react";
import { DealerContext } from "../contexts/DealerContext";
import { makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    position:'relative',
    maxHeight:'calc(100vh - 63px)'
  },
  imgHolder: {
    padding: "50px",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    height: "150px",
  },
  Carousel: {
    transform: (slidePosition) => `translate(${slidePosition}%, 0px)`,
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    transition: "0.5s all ease-in",
  },
  nextbtn: {
    background: 'transparent',
    border: 'white 5px solid',
    borderRadius: '50%',
    right: "0",
    padding: "10px",
    color: "white",
    opacity: "1",
    margin:'10px',
    cursor:'pointer',
    transition: "0.1s all ease-in",
    "&:hover": {
      background: 'transparent',
      border: 'white 5px solid',
      color: "white",
      opacity: "0.8",
    },
  },
  backbtn: {
    background: 'transparent',
    border: 'white 5px solid',
    borderRadius: '50%',
    left: "0",
    padding: "10px",
    color: "white",
    opacity: "1",
    margin:'10px',
    cursor:'pointer',
    transition: "0.1s all ease-in",
    "&:hover": {
      background: 'transparent',
      border: 'white 5px solid',
      color: "white",
      opacity: "0.8",
    },
  },
  btnHolder: {
    display: "flex",
    position: "absolute",
    bottom:'20px',
    justifyContent: "space-between",
  },
  img:{
    filter: 'brightness(0.9)',
  }
}));

const Carousel = (props) => {
  const [loading, setLoading] = useState(false);
  const [slidePosition, setSlidePosition] = useState(0);
  const [slideChanged, setSlideChanged] = useState(0);

  const [slideIndex, setSlideIndex] = useState(0);
  const [slides, setSlides] = useState(props?.slides);
  const classes = useStyles(slidePosition);

  const [slidesLoading, setSlidesLoading] = useState(true);
  const [slidesHovering, setSlidesHovering] = useState(false);

  useEffect(() => {
    setSlides(props?.slides)
    setSlidesLoading(false)
  }, [props])


  const handleNextSlide = () => {

    if(slideIndex < slides.length - 1){

      setSlideIndex(slideIndex + 1)
    }
    if (slideIndex === slides.length - 1) {

      setSlideIndex(0)
  }

  setTimeout(() => {
    setSlidesHovering(true)
}, 5000);

  };

  const handlePreSlide = () => {
    if(slideIndex > 0){

      setSlideIndex(slideIndex - 1)
    }

    setTimeout(() => {
      setSlidesHovering(true)
  }, 5000);

  };

  useEffect(() => {
    if(!slidesLoading && !slidesHovering){

     setTimeout(() => {
         handleNextSlide()
     }, 5000);

    }
     })

  return (
    <div className={classes.root} onMouseEnter={()=>{setSlidesHovering(true)}} onMouseOver={()=>{setSlidesHovering(true)}} onMouseLeave={()=>{setSlidesHovering(false)}}>
      <div className={classes.Carousel} slidePosition={slidePosition} >
        <Link to={slides[slideIndex]?.url}>
        <img src={slides[slideIndex]?.slideImageUrl} width="100%" className={classes.img}      />
        </Link>
      </div>
      <div className={classes.btnHolder} onMouseEnter={()=>{setSlidesHovering(true)}} onMouseOver={()=>{setSlidesHovering(true)}} onMouseLeave={()=>{setSlidesHovering(false)}}>
        <button
          className={classes.backbtn}
          onClick={() => {
            handlePreSlide();
          }}
        >
          <ArrowBackIosNewIcon />
        </button>
        <button
          className={classes.nextbtn}
          onClick={() => {
            handleNextSlide();
          }}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  );
};
export default Carousel;
