import React, { useContext } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {DEALERID,MASTER_DEALERID,BASE_URI,MASTER_MOTORGROUPID,MOTORGROUPID} from '../shared/Constants'
import { Service } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext'

const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      paddingTop:'60px',
      marginBottom:'60px'
    },
    innerSection: {
        width: "100%",
        maxWidth: "1170px",
        display: "flex",
        justifyContent: "space-between",
        padding: "6px 15px",
      
        '@media (max-width: 959px)': {
          flexWrap:'wrap',
          padding: "6px 0px",
      },
      },
      section:{
        width:'60%',
        
          '&:nth-child(1)':{
            marginRight:'40px',
            width:'40%',
          },
          '@media (max-width: 959px)': {
            width:'100%',
            margin:'15px',
             
          '&:nth-child(1)':{
            margin: '0px',
            padding: '0px 20px',
            width: '100%',
        
          },
        },
      },
      text:{
        fontSize:'16px',
      },
      titleTop:{
        fontSize:'44px',
        fontWeight:'bold'
      },
      titleTopAccent:{
        fontSize:'52px',
        color:'#c7001f',
        fontWeight:'bold'
      }
}))


 

const BookService = () => {
    const classes = useStyles();

    const { dealerList,globalDealer } = useContext(DealerContext)

  return (
    <div className={classes.root}> 
    <div className={classes.innerSection}> 
    <div className={classes.section} > 
    <Typography variant="h1" className={classes.titleTop}>
    BOOK A
    </Typography>
    <Typography variant="h1" className={classes.titleTopAccent}>
    SERVICE
     
        </Typography>

        <Typography variant="body2" className={classes.text}>
        We will happily assist in the service of your vehicle, leave your details and one of our service advisors will contact you shortly.
     
        </Typography>

    </div>
    <div className={classes.section}> 
  
 {console.log("dealerList",dealerList)}
    <Service 
    base_uri={BASE_URI} 
    //serviceType='standard' 
    dealerName={globalDealer?.name?.replace("LSG"," ")?.replace("Master"," ")} 
    //dealerId={4}
    activeMake="CHERY"
   activeMakeId={8104}
   masterDealerId={MASTER_DEALERID}
        dealerId={431}
        activeDealerId={431}
    motorGroupId={MOTORGROUPID} 
    dealerList={dealerList} 
    //models={models} 
    hasMultiDealers={false} 
    //sideBarDetailsHeader={partsDetailsHeading} 
    //sideBarDetails={partsDetails} 
    />

    </div>
    </div>
    </div>
   
  )
}

export default BookService