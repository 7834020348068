import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import Logo from '../shared/assets/Cherry Logo New 2024 Web Dark 1.webp'
import { Link } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { DealerContext } from '../shared/contexts/DealerContext';

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        display: "flex",
        justifyContent: 'center',
        margin:'0px 0px',
      
        flexDirection:'column',
        marginBottom:'0px',
        padding:'40px 100px',
        background: "#d7d6d6",
        '@media (min-width:0px) and (max-width:768px)':{

            padding:'20px 15px'
        }
    },
    innerHolder:{
        width: "100%",
      
        justifyContent: 'space-evenly',
   
        display: "flex",
        flexDirection: 'row',
        '@media (min-width:0px) and (max-width:768px)':{
            flexDirection:'column'
        }
    },
    section:{
        width: 'calc(100%/3)',
        '@media (min-width:0px) and (max-width:768px)':{
            width: "100%",
            padding:'0px 15px'
        }
    },
    sectionFlex:{
        display:'flex',
        '@media (min-width:769px) and (max-width:1024px)':{
     flexDirection:'column'
        }
    },
    
    sectionInnerGroup:{
        width: 'calc(100%/2)',
        '@media (min-width:0px) and (max-width:768px)':{
            width: "100%",
            padding:'0px 15px'
        }
    },
    GroupedSection:{
        display:'flex',
        width: 'calc(100%/3)',
        flexDirection:'column',
        '@media (min-width:0px) and (max-width:768px)':{
            width: "100%",
            padding:'0px 15px'
        }
    },
    heading:{
       color:'#b81b22',
        marginTop:'21px',
        marginBottom:'10px',
        fontSize:'24px',
    },
    
    headingText:{
        fontSize:'16px!important',
        margin:'10px 0px',
        color:'#131313',
    },
    img:{
        display:'block',
        margin:'0px auto'
    },
    imgSection:{
        width: 'calc(100%/3)',
        textAlign:'center',
        '@media (min-width:0px) and (max-width:768px)':{
            width: "100%",
            padding:'0px 15px'
        }
    },
    hr:{
        border: '1px #6c757d70 solid',
        width: '100%',
    },
    legal:{
        fontSize:'8pt'
    },
    socialLinkHolder:{
        display:'flex'
    },
    socialLink:{
        width:'calc(100%/11)!important',
            color:'black!important',
            '@media (min-width:769px) and (max-width:1024px)':{
                width:'calc(100%/6)!important',
                   }
    },
    socialLinkIcon:{
        width:'80%!important',
        height:'80%!important',
    }
   
}))

const Footer = () => {
    const classes = useStyles();

    const {globalDealer} = useContext(DealerContext)

  return (
    <div className={classes.root}>
            <div className={classes.innerHolder}>
        <div className={classes.section}>
           <Typography variant='h1' className={classes.heading} ><strong>SITEMAP </strong></Typography>
           <div className={classes.sectionFlex}>
           <div className={classes.sectionInnerGroup}>
                <Link to="/show-room"> <Typography variant='h1' className={classes.headingText} > Showroom   </Typography></Link>
                <Link to="/parts"> <Typography variant='h1' className={classes.headingText} > Parts   </Typography></Link>
           
                <Link to="/special-deals"> <Typography variant='h1' className={classes.headingText} > Deals </Typography></Link>
           
                <Link to="/apply-for-finance"><Typography variant='h1' className={classes.headingText} > Finance  </Typography></Link>
                </div>
                <div className={classes.sectionInnerGroup}>
                <Link to="/sell-your-vehicle"><Typography variant='h1' className={classes.headingText} > Sell a Car</Typography></Link>
                <Link to="/contact-us"><Typography variant='h1' className={classes.headingText} > Contact Us  </Typography></Link>
                </div>
               
                </div>
               

              
        </div>

        <div className={classes.section}>
           <Typography variant='h1' className={classes.heading} ><strong>FOLLOW US  </strong></Typography>

           <div className={classes.socialLinkHolder}>
           <div className={classes.socialLink}>
           <a href={globalDealer?.social?.facebook} target="_blank" style={{color:'black'}} rel="noreferrer">
           <FacebookIcon className={classes.socialLinkIcon}/>
           </a>
           </div>

           <div className={classes.socialLink}>
           <a href={globalDealer?.social?.instagram} target="_blank" style={{color:'black'}} rel="noreferrer">
           <InstagramIcon className={classes.socialLinkIcon}/>
           </a>
           </div>

           <div className={classes.socialLink}>
           <a href={globalDealer?.social?.twitter} target="_blank" style={{color:'black'}} rel="noreferrer">
           <TwitterIcon className={classes.socialLinkIcon}/>
           </a>
           </div>

           <div className={classes.socialLink}>
           <a href={globalDealer?.social?.linkedIn} target="_blank" style={{color:'black'}} rel="noreferrer">
           <LinkedInIcon className={classes.socialLinkIcon}/>
           </a>
           </div>

           <div className={classes.socialLink}>
           <a href="https://www.youtube.com/channel/UCRDII1nURvFtdBYKG90cPMQ" target="_blank" style={{color:'black'}} rel="noreferrer">
           <YouTubeIcon className={classes.socialLinkIcon}/>
           </a>
           </div>

           </div>
           <div className={classes.section}>
           <Typography variant='h1' className={classes.heading} ><strong>ADDRESS </strong></Typography></div>
           <p className={classes.headingText} ><strong>
           Cnr of Commissioner and Muller Street, <br /> 
           Bethlehem, 9701</strong></p>
        </div>
     
        <div className={classes.GroupedSection}>
        <div>
           <Typography variant='h1' className={classes.heading} ><strong>CUSTOMER CARE  </strong></Typography>
           <Typography variant='h1' className={classes.headingText} ><strong>customercare@chery.co.za</strong></Typography>
           <Typography variant='h1' className={classes.headingText} ><strong>010 448 5388</strong></Typography>
        </div>
        <div>
           <Typography variant='h1' className={classes.heading} ><strong>ROADSIDE ASSISTANCE</strong></Typography>
           <Typography variant='h1' className={classes.headingText} ><strong>086 150 5555</strong></Typography>
        </div>
        </div>

        <div className={classes.imgSection}>
           <Typography variant='h1' className={classes.heading} ><img src={Logo}/></Typography>
           <Typography variant='h1' className={classes.headingText} >Copyright - Chery 2020 - Privacy<br/> Policy</Typography>
           <Typography variant='h1' className={classes.headingText} >All content on this website is<br/> subject to change without notice</Typography>
        </div>

        </div>

        <hr className={classes.hr}/>

        <div className={classes.legal}>
        <Typography variant='h1' className={classes.legal} >
        Terms and Conditions Apply. No responsibility is accepted for any errors and omissions. The material on this website is for informational purposes only. Chery International (PTY) LTD reserves the right to change the information referred to on this site at any time without prior notice. Consult your Chery dealer for the latest information and availability. Chery International (PTY) LTD reserves the right to alter any details of specifications without notice. The information contained in this website is an overview intended for awareness purposes only. Images may show specifications not available in South Africa images may be shown for illustration purposes only. Please check model availability and full specification details with your Chery dealer. Neither Chery International (PTY) LTD nor a Chery Dealer network can accept contractual responsibility for your reliance on the information contained within this site.
        ADDR:41.72.157.210 - PHPV: 7.4.29 - CMSV: 6.0
        </Typography>
        </div>
    </div>
  )
}

export default Footer