import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, DEALERID, MASTER_MOTORGROUPID,MOTORGROUPID, MASTER_DEALERID, MASTER_DEALER_NAME } from '../Constants'

export const DealerContext = createContext();

export const DealerProvider = ({ children }) => {

    const getItem = (itm) => {
        let data = localStorage.getItem(itm);

        if (data && data != "undefined")
            return JSON.parse(data)
        else
            return {
                id: MASTER_DEALERID,
                name: null
            }
    }

    const defaultColors = {
        primaryColor: '#F5ED32',
        secondaryColor: '#d6ebf9',
        accentColor: '#ffffff',

    }
    const setItem = (name, object) => {
        var stringObject = JSON.stringify(object);
        localStorage.setItem(name, stringObject);
    }

    const [globalDealer, setGlobalDealer] = useState({});
    const [websiteColors, setWebsiteColors] = useState(getItem('websiteColors'))
    const [dealerList, setDealerList] = useState()
    const [dealerListDirty, setDealerListDirty] = useState()
    useEffect(() => {
        const getWebsiteColors = async () => {
            try {
                const result = await axios({
                    method: 'GET',
                    url: `${BASE_URI}/DealerWebsites/Colors/${DEALERID}`
                })

                if (result?.data) {
                    const colorsObject = result?.data

                    setWebsiteColors(colorsObject);
                    setItem('websiteColors', colorsObject)
                }

            } catch (error) {
            }
        }


        getWebsiteColors();

    }, [])

    useEffect(() => {
        axios.get(`${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`, {

        }).then((result) => {
            let arr = []
            for (let index = 0; index < result.data.length; index++) {
                const element = result.data[index];
                element.id = element.dealerId
                if(element.dealerId == 431 ){
                    arr.push(element)

                }
                if(element.dealerId == 432){
                    arr.push(element)
                }
            }

            // var filtered = result.data.filter(d => d.dealerId.includes())
            console.log("data",arr)
            setDealerList(arr)

            setDealerListDirty( result.data)
        });
    }, [])

    useEffect(() => {
        if (!websiteColors || !websiteColors?.primaryColor || !websiteColors?.secondaryColor || !websiteColors?.accentColor) {
            setWebsiteColors({
                ...websiteColors,
                primaryColor: websiteColors?.primaryColor ? websiteColors?.primaryColor : defaultColors.primaryColor,
                secondaryColor: websiteColors?.secondaryColor ? websiteColors?.secondaryColor : defaultColors.secondaryColor,
                accentColor: websiteColors?.accentColor ? websiteColors?.accentColor : defaultColors.accentColor
            })
        }
    }, [websiteColors])

    const lookupDealer = async (name)=> {

        try{
            const id = name === MASTER_DEALER_NAME ? MASTER_MOTORGROUPID : MOTORGROUPID;
            const response = await axios.get(`${BASE_URI}/Dealers/${id}/${name}`);

            const dealer = {
              id: response.data.id,
              name: response.data.name,
              makes: response.data.makes,
              social: response.data?.social,
              resolved: true,
            };
            setGlobalDealer(dealer);
        }
        catch(error){
            console.error(error);
        }
    }
    useEffect(() => {
        // use /dealer if present.
        const parsedData = window.location.pathname.split("/");
        const isDealerRoute = parsedData.length > 1 && parsedData[1] === "dealer";
        const dealerName = isDealerRoute ? parsedData[2] : null;
    
        if (dealerName && dealerName.length > 0) {
            lookupDealer(dealerName);
        } else {

            const fullUrl = window.location.href;
            const host = window.location.host;

            if (
                fullUrl.includes('cheryupington.co.za') || 
                host === 'www.cheryupington.co.za' || 
                host === 'cheryupington.co.za'
            ) {
                window.location.href = 'https://www.cherybethlehem.co.za';
            } else if (
                fullUrl.includes('cherybethlehem.co.za') || 
                host === 'www.cherybethlehem.co.za' || 
                host === 'cherybethlehem.co.za'
            ) {
                lookupDealer('LSG Chery Bethlehem');
            } else {
                lookupDealer(MASTER_DEALER_NAME);
            }
        }
    }, []);
    

    return (
        <DealerContext.Provider value={{globalDealer, websiteColors,dealerList,dealerListDirty}}>
            {children}
        </DealerContext.Provider>
    )
};