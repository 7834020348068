import React,{useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "50px",
    position: "fixed",
    zIndex: 5,
    background: "#1d1d1de0",
    top: "0",
    justifyContent: "center",
    height: "100vh",
    backdropFilter:'blur(1px)'
  },
  popUp: {
    
 display:'flex',
 flexDirection:'column',
    position:'relative'
  },
  popUpClose: {
      cursor:'pointer',
    color: "#fff",
    position:'absolute',
    right:'0px',
    top:"-40px",
    fontSize:'20px',
    fontWeight:'bold'
  },
  btnHolder:{
    border:"none",
    position: 'absolute',
    display: 'flex',
    top: '50%',
    transform: 'translate(0%, -50%)',
    width: '100%',
    justifyContent: 'space-between',
},
btnBack:{
  border:"none",
  color:'#fff',
  cursor:'pointer',

  marginLeft:'-40px',
  '@media (max-width: 959px)': {
    marginLeft:'0px'
}
},
  btnNext:{
      border:"none",
      color:'#fff',
      cursor:'pointer',
      marginRight:'-40px',
      '@media (max-width: 959px)': {
        marginRight:'0px'
    }
  },
  count:{
      color:'#fff',
      textAlign:'right',
      fontSize:'12px',
      padding:'5px 0px'
  }
}));

const LightBox = (props) => {
  const classes = useStyles();
 const [index, setIndex] = useState(0)

 useEffect(() => {

    setIndex(props.index)

 }, [props])
 

 const handleBack = () =>{
     if(index > 0){
        setIndex(index - 1)
     }

}

const handleNext = () =>{
    if(index < props?.activeGallery.length -1){
        setIndex(index + 1)
     }

}

  return (
    <div className={classes.root}>
      <div className={classes.popUp}>
        <div
          className={classes.popUpClose}
          onClick={() => {
            props.setActiveImg("");
          }}
        >
          X
        </div>
        {console.log("index",index)}
        {console.log("activeImg",props?.activeGallery)}
       
        <img src={props?.activeGallery[index]?.image?.imageUrl} width="100%"/>
        <span className={classes.count}>{index + 1 } / {props?.activeGallery.length }</span>
        <div className={classes.btnHolder}>
        <div className={classes.btnBack} onClick={()=>{handleBack()}}><ArrowBackIosNewIcon/></div>
        <div  className={classes.btnNext} onClick={()=>{handleNext()}}>    <ArrowForwardIosIcon /></div>
        </div>
      </div>

    
    </div>
  );
};

export default LightBox;
