import React, { useContext } from 'react'
import { SellYourVehicleWePay4Cars } from '@red-build/leadcentre'
import { makeStyles } from '@material-ui/core'
import {BASE_URI, WEPAY4CARS_URI } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext'
import SellYourVehicleImg from '../shared/assets/sell-a-car-banner.jpg'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop:'60px',
    marginBottom:'60px'
  },
  innerSection: {
      width: "100%",
      maxWidth: "1170px",
      display: "flex",
      justifyContent: "space-between",
      padding: "6px 15px",
    
      '@media (max-width: 959px)': {
        flexWrap:'wrap',
        padding: "6px 0px",
    },
    },
    section:{
      width:'60%',
   
      '& div:first-child':{
     
    
      },
        '&:nth-child(1)':{
          marginRight:'40px',
          width:'40%',
          padding:"0px!important"
        },
        '@media (max-width: 959px)': {
          width:'100%',
          margin:'15px',
          width:'92%',
        '&:nth-child(1)':{
          margin:'0px',
     
        },
      },
    },
    section2:{
      width:'40%',
    
      '& div:first-child':{
     
        padding:"5px 0px!important"
      },
      
        '&:nth-child(1)':{
          marginRight:'40px',
          width:'40%',
          padding:"40px 0px",
        },
        '@media (max-width: 959px)': {
          width:'100%',
          margin:'15px',
           
          '&:nth-child(1)':{
            margin: '0px',
            padding: '0px 20px',
            width: '100%',
          
          },
      },
    },

    text:{
      fontSize:'16px',
    },
    titleTop:{
      fontSize:'44px',
      fontWeight:'bold'
    },
    titleTopAccent:{
      fontSize:'52px',
      color:'#c7001f',
      fontWeight:'bold'
    },
  imgHolder:{
    maxWidth: '1170px',
    marginTop:'50px'
  },
  
}))

const SellVehicle = () => {

  const { globalDealer, dealerList } = useContext(DealerContext)
  const classes = useStyles();

  let defaultColors = {
    primaryColor: "pink",
    secondaryColor: "pink",
    accentColor: "pink",
  }

  return (
    <div className={classes.root}> 
    <div className={classes.innerSection}> 
    <div className={classes.section2} > 
    <Typography variant="h1" className={classes.titleTop}>
    SELL A
    </Typography>
    <Typography variant="h1" className={classes.titleTopAccent}>
    CAR
     
        </Typography>

        <Typography variant="body2" className={classes.text}>
        Leave a message, we will get back to you.
     
        </Typography>

    </div>
    <div className={classes.section}> 
  
 
    <SellYourVehicleWePay4Cars base_uri={BASE_URI} wePay4Cars_uri={WEPAY4CARS_URI} dealerId={globalDealer.id} active leadSourceId={15} websiteColors={defaultColors} dealerList={dealerList} />
    </div>
    </div>
    </div>
  
  )
}

export default SellVehicle