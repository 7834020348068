import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ContactUs } from "@red-build/leadcentre";
import { BASE_URI } from '../shared/Constants'
import { Link } from "react-router-dom";
import DealsFilter from "../SpecialDeals/DealsFilter";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 15px",
  },
  innerHolder: {
    maxWidth: "1170px",
    width: "100%",
  },
  CardHolder: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    marginTop: "20px",
    marginBottom:'20px',
  },
  CardHolder50: {
    background: "white",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "30px 0px",
    // marginTop: "0px 10px",
    marginTop: "20px",
    width:'calc(100%/2 - 10px)',

    '@media (max-width: 959px)': {
        width:'calc(100%)',
    }
  },

  cardContent: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 30px",
    flexDirection: "column",
  },
  cardContent50: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    padding: "0px 0px",
    flexDirection: "column",
  },
  title: {
    borderLeft: "3px #b81b22 solid",
    padding: "5px 30px",
    alignItems: 'center',
    display: "flex",
    justifyContent: "space-between",
  },
  cardContentItem:{
      display: 'flex',
      padding: '10px 0px',
      borderBottom: '2px #8080802e solid',
      justifyContent: 'space-between',
      alignItems: 'center',

  },
  cardContentItemNotCashDeal: {
    display: 'flex',
    padding: '10px 0px',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  CardHolderWrap: {
    display: 'flex',

    justifyContent: 'space-between',
   flexWrap:'wrap',

},
accent:{
    color:'#b81b22 '
},
tc:{
    padding:'10px',
    paddingTop:'20px'
},
titleTop:{
  fontSize:'20px',
  fontWeight:'unset'
},
titleTopCashDealContent:{
  fontSize:'22px',
  fontWeight:'unset'
},
introParagraph:{
  fontSize: '16px'
},
titleTopAccent:{
  fontSize:'20px',
  color:'#c7001f',
  fontWeight:'bold'
},
Link: {
  border: "none",
  borderRadius:'1rem',
  padding: "10px 40px",
  background: "#b81b22",
  color: "white",
  cursor: "pointer",


    "&:hover": {
      background: "  #9b161c",
      boxShadow: "0 0 5px 0  #9b161c",
    },
  },
  titleFlex: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: '0px 10px',
    background: "#b81b22",
    minWidth: '3px',
    minHeight: '35px'
  },
}));

const DetailedSpecialDeals = () => {
  const [offer, setOffer] = useState();
  const params = useParams();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [sortDirection, setSortDirection] = useState('Desc')
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1)

  const{
    // loading,
    deals,
    // hasMore
  }= DealsFilter(pageNumber, sortDirection)

  useEffect(() => {
    setOffer(deals.filter((v=> v.id == params?.stockId))[0]);
  }, [params,deals]);

  useEffect(() => {
    console.log(offer);
  }, [offer]);

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
      {offer?.isCashDeal && (
        <>
      <div className={classes.CardHolder}>
          <div className={classes.title}>
          <div className={classes.titleFlex}>
          <Typography variant="h4" className={classes.titleTop}>
          {offer?.headline}
        </Typography>
        <div className={classes.divider}></div>
              </div>
              
        <Link
              to="/apply-for-finance"

            >
              <button className={classes.Link} >Pre-Qualify</button>
            </Link>
          </div>


        </div>
        <img alt="offer_image" src={offer?.imageUrl} width="100%" />
      <div className={classes.CardHolderWrap}>
        <div className={classes.CardHolder50}>
          {/* <div className={classes.title}>
            <Typography variant="h4" className={classes.titleTop}>
              Summary
            </Typography>
          </div> */}


          <div className={classes.cardContent}>

          <div className={classes.cardContentItem}>
          <Typography variant="h2" className={classes.titleTopCashDealContent}>
          {offer?.headline}
        </Typography>

            </div>

            <div className={classes.cardContentItemNotCashDeal}>

          <Typography variant="h6" className={classes.titleTop}>
          {offer?.introParagraph}
        </Typography>

            </div>

          </div>
        </div>
        <div className={classes.CardHolder50}>
          <div className={classes.title}>
            <Typography variant="h4" className={classes.titleTop}>
                Contact Us
            </Typography>
          </div>

          <div className={classes.cardContent50}>

          <ContactUs dealerId={offer?.dealerId} base_uri={BASE_URI} leadTypeId={5} dealerName={offer?.dealershipName}/>
          </div>
            </div>
          </div>
          <Typography variant="body" className={classes.tc}>
            {offer?.termsAndConditions}
          </Typography>
          </>
        )}

        {!offer?.isCashDeal && (
          <>
          <div className={classes.CardHolder}>
          <div className={classes.title}>
          <div className={classes.titleFlex}>
          <Typography variant="h4" className={classes.titleTop}>
          {offer?.headline}
        </Typography>
        <div className={classes.divider}></div>
              <Typography variant="h4" className={classes.titleTop}>
                {formatter.format(offer?.price)}
              </Typography>
              </div>
              
        <Link
              to="/apply-for-finance"

            >
              <button className={classes.Link} >Pre-Qualify</button>
            </Link>
          </div>


        </div>
        <img alt="offer_image" src={offer?.imageUrl} width="100%" />
      <div className={classes.CardHolderWrap}>
        <div className={classes.CardHolder50}>
          {/* <div className={classes.title}>
            <Typography variant="h4" className={classes.titleTop}>
              Summary
            </Typography>
          </div> */}


          <div className={classes.cardContent}>

          <div className={classes.cardContentItem}>
          <Typography variant="h6" className={classes.titleTop}>
          Purchase Price
            </Typography>

            <Typography variant="body" className={classes.accent}>
            {formatter.format(offer?.price)}
            </Typography>
            </div>

          <div className={classes.cardContentItem}>
          <Typography variant="h6" className={classes.titleTop}>
              Monthly Installment
            </Typography>
            <Typography variant="body" className={classes.accent}>
            R {offer?.monthlyInstallment}
            </Typography>
            </div>



            <div className={classes.cardContentItem}>
          <Typography variant="h6" className={classes.titleTop}>
                Term
            </Typography>

            <Typography variant="body" className={classes.accent}>
             {offer?.term}
            </Typography>
            </div>

            <div className={classes.cardContentItem}>
          <Typography variant="h6" className={classes.titleTop}>
          Linked Interest Rate
            </Typography>

            <Typography variant="body" className={classes.accent}>
               {offer?.rate}
            </Typography>
            </div>

            <div className={classes.cardContentItem}>
          <Typography variant="h6" className={classes.titleTop}>
                Deposit
            </Typography>

            <Typography variant="body" className={classes.accent}>
               {offer?.deposit} %
            </Typography>
            </div>

            <div className={classes.cardContentItem}>
          <Typography variant="h6" className={classes.titleTop}>
          BalloonPayment
            </Typography>

            <Typography variant="body" className={classes.accent}>
               {offer?.balloonPayment} %
            </Typography>
            </div>
          </div>
        </div>
        <div className={classes.CardHolder50}>
          <div className={classes.title}>
            <Typography variant="h4" className={classes.titleTop}>
                Contact Us
            </Typography>
          </div>

          <div className={classes.cardContent50}>

          <ContactUs dealerId={offer?.dealerId} base_uri={BASE_URI} leadTypeId={5} dealerName={offer?.dealershipName}/>
          </div>
            </div>
          </div>
          <Typography variant="body" className={classes.tc}>
            {offer?.termsAndConditions}
          </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default DetailedSpecialDeals;