import React, { createContext, useState, useEffect } from 'react';

export const CompareContext = createContext();

export const CompareProvider = ({ children }) => {

    const [compareVal, setCompareVal] = useState([]);


    return (
        <CompareContext.Provider value={{ compareVal, setCompareVal }}>
            {children}
        </CompareContext.Provider>
    )
};