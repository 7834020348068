import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import HomeComponent from './HomeComponent/HomeComponent';
import ScrollUpButton from "react-scroll-up-button";
import TopBar from './TopBar/TopBar';
import Footer from '../components/Footer/Footer'
import Showroom from './Showroom/Showroom';
import SellVehicle from './SellVehicle/SellVehicle';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';
import DetailedComingSoon from './DetailedVehicle/DetailedComingSoon';
import { BrowserRouter as Router } from 'react-router-dom';
import VehicleFinance from './Finance/VehicleFinance';
import ContactUs from './ContactUs/ContactUs';
import BookService from './BookService/BookService';
import SpecialDeals from './SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from './DetailedSpecialDeals/DetailedSpecialDeals';
import BranchLocator from './shared/BranchLocator/BranchLocator';
import Parts from './Parts/Parts';
import UsedVehicles from './UsedVehicles/UsedVehicles';
import CheryApp from './CheryApp/CheryApp';
import DetailedUsedVehicle from './DetailedUsedVehicle/DetailedUsedVehicle';
import Dealers from './Dealers/Dealers';
import BookATestDrive from './BookATestDrive/BookATestDrive';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiTextField-root": {
            margin: theme.spacing(2),
            width: "45ch",
        },
    },
    container: {
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    appBarSpacer: {},
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
}));

export default function DashboardContainer() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <main>
             <TopBar link1Text='HOME' link2Text='Vehicles in stock' link3Text='Sell' link4Text='Finance' link5Text='Contact Us' link6Text='Compare' link7Text='Favourites'/>     
                    <Route exact path="/"render={(props) => <HomeComponent />} />
                    <Route exact path="/dealer/:name"render={(props) => <HomeComponent />} />  
                    <Route exact path="/show-room"render={(props) => <Showroom />} /> 
                    <Route  path="/show-room/:model/:stockId"render={(props) => <DetailedVehicle />} />
                    <Route  path="/coming-soon/:model/:stockId"render={(props) => <DetailedComingSoon />} />  
                    <Route exact path="/used-vehicles"render={(props) => <UsedVehicles />} /> 
                    <Route exact path="/book-a-testdrive"render={(props) => <BookATestDrive />} /> 
                    <Route exact path="/book-a-testdrive/:stockId"render={(props) => <BookATestDrive />} /> 
                    <Route  path="/used-vehicles/:make/:stockId"render={(props) => <DetailedUsedVehicle />} />
                
                    <Route exact path="/book-a-service"render={(props) => <BookService />} /> 
                    <Route exact path="/branch-locator"render={(props) => <Dealers />} /> 
                    <Route exact path="/parts"render={(props) => <Parts />} /> 
                    <Route exact path="/sell-your-vehicle"render={(props) => <SellVehicle />} /> 
                    <Route exact path="/special-deals"render={(props) => <SpecialDeals />} /> 
                    <Route  path="/special-deals/:make/:stockId"render={(props) => <DetailedSpecialDeals />} /> 
                     <Route exact path='/apply-for-finance' component={VehicleFinance} />
                     <Route exact path='/contact-us' component={ContactUs} />
                     <Route exact path='/chery-app' component={CheryApp} />
                     
                     <BranchLocator />
                    <Footer />
                <ScrollUpButton />
            </main>
        </React.Fragment>
    );  
}