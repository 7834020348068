import React,{useEffect,useState,useContext} from 'react'
import axios from 'axios'
import { makeStyles } from "@material-ui/core";
import {BASE_URI, DEALERID} from '../shared/Constants'
import Left from '../shared/assets/SpecialDeals.jpg'
import Right from '../shared/assets/T1D.jpg'
import Carousel from '../shared/Carousel/Carousel'
import { Typography, TextField, Grid, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { DealerContext } from '../shared/contexts/DealerContext';
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    padding:'50px 0px',

    '@media (min-width:0px) and (max-width:425px)':{
      flexWrap:'wrap'
    }
  },
  section: {
    width: "540px",
    height:'540px',
    margin:'0px 15px',
    color:'white',
  backgroundSize:'cover',
  backgroundPosition: 'center top',
  '@media (min-width:0px) and (max-width:425px)':{
    height:'240px',
    margin:'15px',
  },
  '@media (min-width:426px) and (max-width:1024px)':{
    height:'340px',
    margin:'15px',
  }
  },
  textSextion:{
    padding:'24px',
    textAlign:'center'
  },
  titleTop:{
    fontSize:'28px',
    marginBottom:'8px'
  },
  sub:{
    fontSize:'16px',
    marginBottom:'16px'
  },
  bold:{
    fontWeight:'bold',

  },
  ctaBtn:{
    border:'1px solid #f8f9fa',
    background:'transparent',
    color:'white',
    padding:'10px 40px',
    borderRadius: '1rem',
    cursor:'pointer',
    "&:hover":{
      boxShadow: "0 0 5px 0 #4d0b0e ",
    }
  }
}))

const HomeComponent = () => {

  const [slides, setSlides] = useState()
  const classes = useStyles();
  const { globalDealer } = useContext(DealerContext)

  useEffect(() => {
    if (Object.keys(globalDealer).length > 0) {
      const params ={
        dealerId: globalDealer.id,
        pageNumber:1,
        pageSize:25,
      }
      axios({
        method: "GET",
        url: `${BASE_URI}/Slide`,
        params,
      }).then((response) => {
        setSlides(response.data.list)
      });
    }
  }, [globalDealer]);

  return (
    <div>
      {slides &&
      <Carousel slides={slides}/>
    }

    <div className={classes.root}>
    <div className={classes.section} style={{background:`linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 51.04%, rgba(0, 0, 0, 0) 100%),url(${Left})`}}>
    <div className={classes.textSextion}>
       <Typography variant="h1" className={classes.titleTop}>

        <span className={classes.bold}>Special</span>  Deals
         </Typography>
         <Typography variant="h1" className={classes.sub}>
         Browse our deals

         </Typography>
         <Link
              to="/special-deals"
            >
             <button className={classes.ctaBtn}>View Deals</button>
            </Link>

        </div>
    </div>

    <div className={classes.section} style={{background:`linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 51.04%, rgba(0, 0, 0, 0) 100%),url(${Right})`}}>

       <div className={classes.textSextion}>
       <Typography variant="h1" className={classes.titleTop}>

        <span className={classes.bold}>Chery</span> Aftersales
         </Typography>
         <Typography variant="h1" className={classes.sub}>
         Book your service

         </Typography>
         <Link
              to="/book-a-service"

            >
           <button className={classes.ctaBtn}>Book service</button>
            </Link>

        </div>

    </div>
    </div>

    </div>
  )
}

export default HomeComponent