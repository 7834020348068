import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { Typography, TextField, Grid, MenuItem } from "@material-ui/core";
import axios from "axios";
import { BASE_URI, DEALERID, MASTER_DEALERID } from "../shared/Constants";
import LightBox from "../shared/LightBox/LightBox";
import { Finance } from "@red-build/leadcentre";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ContactUs as Form } from "@red-build/leadcentre";
import { DealerContext } from "../shared/contexts/DealerContext";
import { display } from "@material-ui/system";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "50px",
  },
  imageHolder: {
    width: "100%",
    height: "calc(100vh - 72px)",
    backgroundSize: "cover",

    backgroundPosition: "center",
    "@media (max-width: 959px)": {
      height: "calc(60vh - 72px)",
    },
  },
  imageHolderText: {
    fontSize: "4rem",
    fontWeight: "bold",
    padding: "20px 50px",
    color: "white",
    textTransform: "uppercase",
    filter: "drop-shadow(2px 4px 6px black)",
    "@media (max-width: 959px)": {
      padding: "20px",
    },
  },
  imageHolderTextWrap: {
    fontWeight: "bold",

    color: "white",
    textTransform: "uppercase",
  },
  bold: {
    color: "#b81b22",
    fontSize: "100px",
    fontWeight: "bold",
    fontSize: "6rem",
  },
  accent: {
    color: "#b81b22",
    fontSize: "45px",
  },
  tabHolder: {
    background: "#b6b6b6",
    width: "100%",
    padding: "0px 0px",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    marginBottom: "40px",
    transition: "0.5s all ease-in",
    transform: ({ tabChangeAmount }) => `translate(${tabChangeAmount}%,0%)`,

    "@media (max-width: 959px)": {
      justifyContent: "unset",
    },
    "@media (max-width: 1100px)": {},
  },
  tabNav: {
    position: "absolute",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "0px 20px",
    transform: "translate(0px , -50%)",
    top: "32%",
    zIndex: "2",
    color: "#b81b22",
  },
  tab: {
    cursor: "pointer",
    borderBottom: "4px #b81b2200 solid",
    background: "#b6b6b6",
    display: "flex",
    alignItems: "center",
    padding: "20px 40px",
    justifyContent: "center",
    fontSize: "1.2rem",
    "@media (max-width: 425px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
    "@media (min-width: 426px) and (max-width: 959px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  tabActive: {
    cursor: "pointer",
    borderBottom: "4px #b81b22 solid",
    background: "#b6b6b6",
    display: "flex",
    alignItems: "center",
    padding: "20px 40px",
    justifyContent: "center",
    fontSize: "1.2rem",
    transform: "scale(1.05)",
    fontWeight: "bolder",
    "@media (max-width: 425px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
    "@media (min-width: 426px) and (max-width: 959px)": {
      minWidth: "calc(100% / 2)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  tabModel: {
    display: "none",
    "@media (max-width: 959px)": {
      background: "grey",
      fontSize: "1.5rem",
      position: "absolute",
      height: "100%",

      display: "flex",
      alignItems: "center",
      minWidth: "calc(100% / 3)",
      padding: "15px 30px",
      fontSize: "1.2rem",
    },
  },
  galleryHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    maxWidth: "1170px",
    width: "100%",
  },
  brochureHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    maxWidth: "1170px",
    width: "100%",
    justifyContent: "center",
    padding: "0px 15px",
  },
  FeatureHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    width: "100%",
    padding: "0px 15px",
  },
  HighlightsHolder: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1170px",
    padding: "0px 15px",
    maxWidth: "1170px",
    width: "100%",
  },
  HighlightsHolderGroup: {
    maxWidth: "calc(100% / 2 - 20px)",
    margin: "10px",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    padding: "20px",
    "@media (max-width: 959px)": {
      maxWidth: "unset",
    },
    "&:nth-child(3)": {
      maxWidth: "unset",
      width: "100%",
    },
  },
  galleryImg: {
    maxWidth: "calc(100% / 3 - 10px)",
    margin: "5px",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
    },

    "&:nth-child(4)": {
      maxWidth: "calc(100% / 2 - 10px)",
    },
    "&:nth-child(5)": {
      maxWidth: "calc(100% / 2 - 10px)",
    },
    "@media (max-width: 959px)": {
      maxWidth: "calc(100% / 2 - 10px)",
      margin: "5px",
    },
  },
  titleTop: {
    margin: "10px 0px",
  },
  featuresCard: {
    maxWidth: "calc(100% / 3 - 20px)",
    minWidth: "calc(100% / 3 - 20px)",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    margin: "10px",
    padding: "20px",
    borderRadius: "3px",
    "@media (max-width: 425px)": {
      maxWidth: "unset",
      width: "100%",
    },
    "@media (max-width: 959px)": {
      maxWidth: "calc(100% / 2 - 20px)",
      width: "100%",
    },
  },
  featuresCardTitle: {
    fontWeight: "bold",
  },
  cta: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "#b81b22",
    marginRight: "10px",
    marginTop: "10px",
    color: "white",
    borderRadius: "1rem",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
  },

  ctaGrey: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "white",
    marginRight: "10px",
    marginTop: "10px",
    borderRadius: "1rem",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
  },
  link: {
    color: "white",
  },
  PricingCard: {
    maxWidth: "calc(100% / 3 - 20px)",
    width: "100%",
    margin: "10px",
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    background: "#b81b22",
    "@media (max-width: 570px)": {
      maxWidth: "unset",
      width: "100%",
    },
    "@media (min-width: 571px) and (max-width: 768px)": {
      maxWidth: "calc(100% / 2 - 20px)",
      width: "100%",
    },
  },
  PricingCardTextHolder: {
    padding: "20px",
    background: "#b81b22",
    color: "white",
  },
  PricingCardText: {
    fontSize: "19px",
    color: "white",
  },
  PricingCardImg: {
    height: "185px",
  },

  tabHolderOutter: {
    overflow: "hidden",
    width: "100%",
    position: "relative",
  },
  tabNavBack: {
    position: "absolute",
    top: "18px",
    left: "5px",
    "@media (min-width: 959px)": {
      display: "none",
    },
  },
  tabNavNext: {
    position: "absolute",
    right: "5px",
    top: "18px",
    "@media (min-width: 959px)": {
      display: "none",
    },
  },
  ctaHolder: {
    width: "100%",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    margin: "10px auto",
    maxWidth: "1170px",
    padding: "0px 15px",
    "@media (max-width: 959px)": {
      flexDirection: "column",
    },
  },
  ctaHolderOutter: {
    background: "#b81b22",
    width: "100%",
  },
  ctaIntro: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "white",
  },
  ctaTop: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "white",
    marginRight: "10px",
    borderRadius: "1rem",
    color: "black",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
  },
  contactPopUpHolder: {
    position: "fixed",
    background: "#000000b8",
    width: "100%",
    height: "100vh",
    top: "0px",
    zIndex: "5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contactPopUpHolderInner: {
    background: 'white',
    width: "40%",
    "@media (max-width: 425px)": {
      width: "90%",
    },
  },
  Close: {
    color: "red",
    textAlign: "right",
    padding: "15px 15px",
    fontSize: "20px",
    cursor: "pointer"
  },
}));

const DetailedComingSoon = () => {
  let tabs = [
    {
      name: "Highlights",
      active: true,
    },
  ];

  const { vehiclesList, setVehiclesList, newVehiclesList } =
    useContext(VehicleContext);
  const [activeVehicles, setActiveVehicles] = useState();
  const [activeTab, setActiveTab] = useState("Highlights");
  const [tabList, setTabList] = useState(tabs);
  const [tabChangeAmount, setTabChangeAmount] = useState(0);
  const [totalTabLenght, setTotalTabLenght] = useState();
  const [activeImg, setActiveImg] = useState("");
  const [activeGallery, setActiveGallery] = useState([]);
  const [pricingList, setPricingList] = useState([]);
  const [contactPopUp, setContactPopUp] = useState(false);
  const { globalDealer, dealerList } = useContext(DealerContext);
  const [activeTitle, setActiveTitle] = useState();
  const paramss = useParams();
  const history = useHistory();

  const classes = useStyles({ tabChangeAmount });

  useEffect(() => {
    setTabList(tabs);
    setTotalTabLenght(tabs.length - 1);
    setActiveTab("Highlights");
    axios({
      method: "GET",
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
    })
      .then((response) => {
        setActiveVehicles(response.data);

        setActiveGallery(response.data.galleryImages);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });

    let params = {
      makeId: 8104,
      dealerModelId: paramss.stockId,
    };
    console.log("paramss", paramss.stockId)
    axios({
      method: "GET",
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {
        console.log("response", response.data.list)
        setPricingList(response.data.list);
      })
      .catch((error) => {
        if (axios.isCancel(error)) return;
      });
  }, [paramss]);

  const handletabChange = (type, tab) => {
    for (let index = 0; index < tabList.length; index++) {
      const element = tabList[index];
      element.active = false;
    }

    tab.active = true;
    setActiveTab(type);
  };

  const handleLightBox = (img, index) => {
    setActiveImg(index);
  };

  const handleContactPopUp = () => {
    setContactPopUp(true);
  };

  const handletabRedirect = (type, tab) => {
    for (let index = 0; index < tabList.length; index++) {
      const element = tabList[index];
      element.active = false;
    }

    let newActiveTab = tabList.filter((t) => t.name == type);

    newActiveTab[0].active = true;

    setActiveTab(type);
  };

  const handleTabNavBack = (val) => {
    if (Math.abs(tabChangeAmount) > 0) {
      setTabChangeAmount(val);
    }
  };

  const handleTabNavNext = (val) => {
    if (Math.abs(tabChangeAmount) < totalTabLenght * 50 - 50) {
      setTabChangeAmount(val);
    }
  };

  useEffect(() => {
    setActiveTitle(newVehiclesList.filter((v) => v.id == paramss?.stockId)[0]);
  }, [paramss, newVehiclesList]);

  var formatter = new Intl.NumberFormat("af", {
    style: "currency",
    currency: "ZAR",
    minimumFractionDigits: 2,
  });

  function openPDF(pdf) {
    window.open(pdf);
    return false;
  }


  function calculateMonthly(data) {
    console.log(data)

    let deposit = data?.priceRange * (10 / 100);
    let totalPrice = data?.priceRange;
    let initFee = 0;
    let principal = totalPrice - deposit + initFee;

    let balloonPerc = 10;

    let balloonAmt = (totalPrice * balloonPerc) / 100;

    let interestRate = 9
    let interestPM = interestRate / 100 / 12;

    let repaymentPeriod = 72;
    let days = 1;

    let v = 1 / (1 + interestPM);
    let d = 1 - v;
    let y = Math.pow(v, repaymentPeriod - 1);
    let comp = (1 - y) / d;
    let fp = principal * Math.pow(1 + interestPM, days / (365 / 12).toFixed());
    let monthly = 0;

    if (parseInt(balloonAmt) > 0) {
      let comp = (1 - Math.pow(v, repaymentPeriod - 1)) / d;
      monthly = (fp - balloonAmt * Math.pow(v, repaymentPeriod - 1)) / comp;
    } else {
      let comp = (1 - Math.pow(v, repaymentPeriod)) / d;
      monthly = fp / comp;
    }

    return round(monthly) + initFee;

  }


  function round(x) {

    return Math.round(x * 100) / 100;

  }

  return (
    <div className={classes.root}>
      {activeImg !== "" && (
        <LightBox
          index={activeImg}
          setActiveImg={setActiveImg}
          activeGallery={activeGallery}
        />
      )}
      <div
        className={classes.imageHolder}
        style={{
          backgroundImage: `url(${activeVehicles?.contentImages[0]?.image?.imageUrl})`,
        }}
      >
        <div className={classes.imageHolderText}>
          {activeTitle?.model?.split(" ")[0]}&nbsp;
          <span className={classes.imageHolderTextWrap}>
            <span className={classes.bold}>
              {activeTitle?.model?.split(" ")[1]}
            </span>
            <span className={classes.accent}>
              {activeTitle?.model?.split(" ")[2]}
            </span>
          </span>
        </div>
      </div>

      {contactPopUp && (
        <div className={classes.contactPopUpHolder}>
          <div className={classes.contactPopUpHolderInner}>
            <div
              className={classes.Close}
              onClick={() => {
                setContactPopUp(false);
              }}
            >
              X
            </div>
            <Form
              dealers={dealerList}
              dealerType="multi_dealer"
              dealerId={globalDealer?.id}
              base_uri={BASE_URI}
              leadTypeId={5}
              dealerName={globalDealer?.name?.replace("LSG", " ")?.replace("Master", " ")}
            />
          </div>
        </div>
      )}
      <div className={classes.ctaHolderOutter}>
        <div className={classes.ctaHolder}>
          <div className={classes.ctaIntro}>
            {activeVehicles?.introductionTitle}
          </div>
          <div>
            <button
              className={classes.ctaTop}
              onClick={() => {
                handleContactPopUp();
              }}
            >
              Call Back
            </button>
          </div>
        </div>
      </div>
      <div className={classes.tabHolderOutter}>
        <div className={classes.tabHolder}>
          {tabList?.map((t, index) => {
            return (
              <div
                className={t?.active == true ? classes.tabActive : classes.tab}
                onClick={() => {
                  handletabChange(t.name, t);
                }}
              >
                {t.name}
              </div>
            );
          })}
        </div>
        <div
          className={classes.tabNavBack}
          onClick={() => {
            handleTabNavBack(tabChangeAmount + 50);
          }}
        >
          <ArrowBackIosNewIcon />
        </div>
        <div
          className={classes.tabNavNext}
          onClick={() => {
            handleTabNavNext(tabChangeAmount - 50);
          }}
        >
          <ArrowForwardIosIcon />
        </div>
      </div>
      {activeTab == "Highlights" && (
        <div className={classes.HighlightsHolder}>
          <div className={classes.HighlightsHolderGroup}>
            <Typography variant="h4" className={classes.titleTop}>
              {activeVehicles?.contentTitle1}
            </Typography>

            <Typography variant="body" className={classes.text}>
              {activeVehicles?.contentDesc1}
            </Typography>
          </div>
          <div className={classes.HighlightsHolderGroup}>
            <Typography variant="h4" className={classes.titleTop}>
              {activeVehicles?.contentTitle2}
            </Typography>

            <Typography variant="body" className={classes.text}>
              {activeVehicles?.contentDesc2}
            </Typography>
          </div>
          <div className={classes.HighlightsHolderGroup}>
            <Typography variant="h4" className={classes.titleTop}>
              {activeVehicles?.contentTitle3}
            </Typography>

            <Typography variant="body" className={classes.titleTop}>
              {activeVehicles?.contentDesc3}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailedComingSoon;
