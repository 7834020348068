import React from 'react'
import { makeStyles } from "@material-ui/core";
import Branch from '../assets/branch.png'
import { Typography, TextField, Grid, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        background: `linear-gradient(180deg, #B81B22 0%, #B81B22 100%), url(${Branch})`,
        backgroundBlendMode: 'multiply, normal',
        display:"flex",
        flexDirection:'column',
        alignItems: 'center',
        padding:'48px 15px'
    },
    titleTop:{
        fontSize:'40px',
        color:'white',
        marginBottom:'8px'
    },
    sub:{
        fontSize:'16px',
        color:'white',
        marginBottom:'16px'
    },
    
    btn: {
        width: 'fit-content',
    padding:'10px 12px',
    borderRadius: '1rem!important',
    textTransform:'uppercase',
    border:'none',
    fontSize:'16px',
    fontWeight:'bold',
    cursor:'pointer',
    "&:hover":{
        boxShadow: "0 0 5px 0 #4d0b0e ",
      }
    },
}))

const BranchLocator = () => {
    const classes = useStyles();
  return (
    <div  className={classes.root}>
             <Typography variant="h1" className={classes.titleTop}>
             Find a branch
     
        </Typography>
        <Typography variant="body2" className={classes.sub}>
        Locate your closest Chery branch
     
        </Typography>
 
        <Link
              to="/branch-locator"
             
            >
             <button className={classes.btn}>   Branch Locator 
      </button>
            </Link>

    </div>
  )
}

export default BranchLocator