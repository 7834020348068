import React, { useContext,useEffect,useState } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {MASTER_DEALERID,BASE_URI,DEALERID} from '../shared/Constants'
import { ContactUs as Form }  from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext';


const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      paddingTop:'60px',
      marginBottom:'60px'
    },
    innerSection: {
        width: "100%",
        maxWidth: "1170px",
        display: "flex",
        justifyContent: "space-between",
        padding: "6px 15px",
      
        '@media (max-width: 959px)': {
          flexWrap:'wrap',
          padding: "0px 0px",
      },
      },
      section:{
        width:'60%',
        
          '&:nth-child(1)':{
            marginRight:'40px',
            width:'40%',
          },
          '@media (max-width: 959px)': {
            width:'100%',
            margin:'15px',
             
            '&:nth-child(1)':{
              margin: '0px',
              padding: '0px 20px',
              width: '100%',
          
            },
        },
      },
      text:{
        fontSize:'16px',
      },
      textBold:{
        fontSize:'16px',
        fontWeight:'bold',
        marginTop:"20px"
      },
      titleTop:{
        fontSize:'44px',
        fontWeight:'bold'
      },
      titleTopAccent:{
        fontSize:'52px',
        color:'#c7001f',
        fontWeight:'bold'
      }
}))

const defaultColors = {
  primaryColor: "#b81b22",
  secondaryColor: "#EFDF00",
};

const ContactUs = () => {
    const classes = useStyles();
    const {globalDealer,dealerList} = useContext(DealerContext)
    const [activeDealer, setActiveDealer] = useState()

    useEffect(() => {
    if (dealerList?.filter((d)=> d.id === globalDealer?.id).length > 0) {
        setActiveDealer(dealerList?.filter((d)=> d.id === globalDealer?.id)[0])
      }
    }, [dealerList, globalDealer, globalDealer.id]);
    
  return (
    <div className={classes.root}> 
    <div className={classes.innerSection}> 
    <div className={classes.section} > 
    <Typography variant="h1" className={classes.titleTop}>
    LEAVE US A
    </Typography>
    <Typography variant="h1" className={classes.titleTopAccent}>
    MESSAGE
     
        </Typography>

        <Typography variant="body2" className={classes.text}>
        Leave a message, we will get back to you.
     
        </Typography>

        <Typography variant="body2" className={classes.textBold}>
        Contact Us
     
        </Typography>
        <Typography variant="body2" className={classes.text}>
     {activeDealer?.newCarsPhoneNumber === undefined ? "075 830 3564" : activeDealer?.newCarsPhoneNumber}
        </Typography>

        <Typography variant="body2" className={classes.textBold}>
        Address: 
     
        </Typography>
        <Typography variant="body2" className={classes.text}>
       
Cnr of Commissioner and Muller Street, Bethlehem, 9701
        </Typography>
    </div>
    <div className={classes.section}> 
  
 
    <Form 
    dealerId={activeDealer?.id} 
    base_uri={BASE_URI} 
    leadTypeId={5} 
  
    masterDealerId={MASTER_DEALERID} 
    activeDealerId={activeDealer?.id} 
    dealerName={globalDealer?.name?.replace("LSG"," ")?.replace("Master"," ")} 
    dealers={dealerList} 
    dealerType="single_dealer" 
    websiteColors={defaultColors} 
    note={true}/>
    </div>
    </div>
    </div>
  )
}

export default ContactUs